import React, { useEffect, useRef, useState } from "react";
import './Preview.css';
import paymentSubmit from '../paymentSubmit'
import {SketchPicker} from "react-color";
import httpBuildQuery from "http-build-query";
import CryptoJS from "crypto-js";
import sha256 from 'js-sha256';

require('dotenv').config(); 
const scale = 0.5;

function Preview({
        handleMarryNameChange, 
        handleFontSizeChange, 
        handleColorChange, 
        excelData, 
        optionSelected, 
        handleLayoutChange, 
        handleElementSelect,
        handlePreviewDownload,
        handleDownloadSubmit,
        scrollRefPreview, 
        canvasRefreshCount, 
        handleCanvasRefresh,
        handlePageNumChange,
        designPropertiesCurrent,
        setDesignPropertiesCurrent,
        designPropertiesDefault,
        elementSelected,
        handleReset
    }){
    let mouseDown = false
    let startX = 0
    let startY = 0
    const layer1 = useRef();
    // const dateNow = Date.now();
    // const trade_info_arr = {
    //     MerchantID: process.env.MERCHANT_ID,
    //     RespondType: 'JSON',
    //     TimeStamp: dateNow,
    //     Version: 1.5,
    //     MerchantOrderNo: dateNow,
    //     Amt: '1',
    //     ItemDesc: '入會費'
    // }
    // const mer_key = process.env.MER_KEY;
    // const mer_iv = process.env.MER_IV;
    // const TradeInfo = create_mpg_aer_encrypt(trade_info_arr, mer_key, mer_iv);
    // const TradeSha = sha256(`HashKey=${mer_key}&${TradeInfo}&HashIV=${mer_iv}`).toUpperCase();


    useEffect(() => {
        let img = new Image();
        img.src = require(`${designPropertiesCurrent.imagePath}`);   //need to use ${} to make it work
        const imgctx = layer1.current.getContext('2d');

        let nameTableList;
        let lastNameTable;
        let name;
        let table;
        
        if (optionSelected === 'copyPaste'){
            nameTableList = designPropertiesCurrent.text.split('\n');
            [lastNameTable] = nameTableList.slice(-1) == '' ? nameTableList.slice(-2) : nameTableList.slice(-1);
            [name, table] = lastNameTable.split(',');
            table = table === undefined ? '': table;
        } 
        else {
            lastNameTable = excelData.slice(-1).length == 0 ? ['', ''] : excelData.slice(-1);
            [name, table] = lastNameTable[0];
            name = name === undefined ? '': name;
            table = table === undefined ? '': table;
        }
        
        img.onload = function(){
            imgctx.clearRect(0, 0, 707 * scale, 1000 * scale);
            imgctx.drawImage(img, (707 * scale - (img.width * 1000 * scale / img.height)) / 2, 0, img.width * ( 1000 * scale / img.height ) , 1000 * scale);
            imgctx.textAlign = "center";
            imgctx.textBaseLine = "middle";
            imgctx.font = `${designPropertiesCurrent.fontSizeTableNum * scale}px Noto Serif TC`;
            imgctx.fillStyle = `${designPropertiesCurrent.fontColorTableNum}`;
            imgctx.fillText(name, designPropertiesCurrent.textPosition.x1 * scale, designPropertiesCurrent.textPosition.y1 * scale);

            imgctx.font = `${designPropertiesCurrent.fontSizeTableName * scale}px Noto Serif TC`;
            imgctx.fillStyle = `${designPropertiesCurrent.fontColorTableName}`;
            imgctx.fillText(table, designPropertiesCurrent.textPosition.x2 * scale, designPropertiesCurrent.textPosition.y2 * scale);

            imgctx.font = `${designPropertiesCurrent.fontSizeMarryName * scale}px Scaramella-Regular`;
            imgctx.fillStyle = `${designPropertiesCurrent.fontColorMarryName}`;
            imgctx.fillText(designPropertiesCurrent.marryName, designPropertiesCurrent.textPosition.x3 * scale, designPropertiesCurrent.textPosition.y3 * scale);

            // //Draw Ruler For determining text position
            // imgctx.beginPath()
            // for (let i = 0; i <= 707; i += 10) {
            //     imgctx.moveTo(i, 0)
            //     if (i % 100 === 0) {
            //         imgctx.lineTo(i, 20)
            //     }
            //     else imgctx.lineTo(i, 10)
            // }
            // for (let j = 0; j <= 1000; j += 10) {
            //     imgctx.moveTo(0, j)
            //     if (j % 100 === 0) {
            //         imgctx.lineTo(20, j)
            //     }
            //     else imgctx.lineTo(10, j)
            // }
            // imgctx.fillStyle = 'black'
            // imgctx.stroke()
        };
        
    }, [designPropertiesCurrent.text, 
        designPropertiesCurrent.imagePath, 
        designPropertiesCurrent.textPosition, 
        designPropertiesCurrent.fontSizeTableNum, 
        designPropertiesCurrent.fontSizeTableName, 
        designPropertiesCurrent.fontSizeMarryName, 
        designPropertiesCurrent.fontColorTableNum, 
        designPropertiesCurrent.fontColorTableName, 
        designPropertiesCurrent.fontColorMarryName, 
        designPropertiesCurrent.marryName, 
        excelData, 
        optionSelected, 
        canvasRefreshCount]);
    //Equivalent to http_build_query in php
    
    //AES 加密function --> TradeInfo
    function create_mpg_aer_encrypt(parameter="", key="", iv=""){
        let returnString = "";
        if(!(parameter == "")){
            //URL encoded query string
            returnString =  httpBuildQuery(parameter);
            // console.log("returnString");
            // console.log(returnString);
        }

        // console.log("addpadding");
        // console.log(addpadding('123456468796123456468796123456468796123456468796123456468796123456468796'));
        // console.log(addpadding(returnString));
        // console.log("CryptoJS");
        // console.log(atob(CryptoJS.AES.encrypt(addpadding(returnString), CryptoJS.enc.Latin1.parse(key), { iv: CryptoJS.enc.Latin1.parse(iv), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding }).toString()));
        
        // console.log("parseInt bin to hex");
        // console.log(bin2hex(atob(CryptoJS.AES.encrypt(addpadding(returnString), CryptoJS.enc.Latin1.parse(key), { iv: CryptoJS.enc.Latin1.parse(iv), mode : CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding }).toString())));
        return bin2hex(atob(CryptoJS.AES.encrypt(addpadding(returnString), CryptoJS.enc.Latin1.parse(key), { iv: CryptoJS.enc.Latin1.parse(iv), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding }).toString())).trim();
    }
    
    function addpadding(string, blocksize = 32) {
        let len = string.length;
        let pad = blocksize - (len % blocksize);
        string = string + (String.fromCharCode(pad).repeat(pad));
        return string;
    }

    function bin2hex (s) {
        //  discuss at: https://locutus.io/php/bin2hex/
        // original by: Kevin van Zonneveld (https://kvz.io)
        // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
        // bugfixed by: Linuxworld
        // improved by: ntoniazzi (https://locutus.io/php/bin2hex:361#comment_177616)
        //   example 1: bin2hex('Kev')
        //   returns 1: '4b6576'
        //   example 2: bin2hex(String.fromCharCode(0x00))
        //   returns 2: '00'
      
        var i
        var l
        var o = ''
        var n
      
        s += ''
      
        for (i = 0, l = s.length; i < l; i++) {
          n = s.charCodeAt(i)
            .toString(16)
          o += n.length < 2 ? '0' + n : n
        }
      
        return o
      }
    
    useEffect(() => { // initializing canvas event
        let nameTableList;
        let lastNameTable;
        let tableNum;
        let tableName;

        if (optionSelected === 'copyPaste'){
            nameTableList = designPropertiesCurrent.text.split('\n');
            [lastNameTable] = nameTableList.slice(-1) == '' ? nameTableList.slice(-2) : nameTableList.slice(-1);
            [tableNum, tableName] = lastNameTable.split(',');
            tableName = tableName === undefined ? '': tableName;
        } 
        else {
            lastNameTable = excelData.slice(-1).length == 0 ? ['', ''] : excelData.slice(-1);
            [tableNum, tableName] = lastNameTable[0];
            tableNum = tableNum === undefined ? '': tableNum;
            tableName = tableName === undefined ? '': tableName;
        }

        const imgctx = layer1.current
        // let boundingBox = imgctx.getBoundingClientRect();
        // let offsetX = boundingBox.left;
        // let offsetY = boundingBox.top;
        // imgctx.onclick = (event) => canvasClicked(event, tableNum, tableName, offsetX, offsetY);
        imgctx.onmousedown = canvasMouseDown;
        window.onmouseup = canvasMouseUp;
        imgctx.onmousemove =  canvasMouseMove;
    }, [elementSelected]);

    // function canvasClicked(event, tableNum, tableName, offsetX, offsetY) {
    //     console.log('canvas mousedown');
    //     console.log('event', event)
    //     const imgctx = layer1.current.getContext('2d')
    //     // const rect = imgctx.getBoundingClientRect()
    //     const mouseX = event.clientX - offsetX
    //     const mouseY = event.clientY
    //     console.log(event.clientY, offsetY)
    //     // check if is in table number range
    //     console.log('tablenum', tableNum)
    //     let tableNumRangeWidth = imgctx.measureText(tableNum).width * designPropertiesCurrent.fontSizeTableNum / 22 * scale
    //     let tableNumRangeHeight = imgctx.measureText('15').width * designPropertiesCurrent.fontSizeTableNum / 22 * scale
    //     let originX = designPropertiesCurrent.textPosition.x1 * scale - tableNumRangeWidth * 0.5
    //     let originY = designPropertiesCurrent.textPosition.y1 * scale - tableNumRangeHeight *0.8
    //     let isIntableNumRange = (mouseX > originX && mouseX < originX + tableNumRangeWidth) && (mouseY > originY && mouseY < originY + tableNumRangeHeight)

    //     console.log(`(${mouseX} > ${originX} && ${mouseX} < ${originX + tableNumRangeWidth}) && (${mouseY} > ${originY} && ${mouseY} < ${originY + tableNumRangeHeight})`)
    //     console.log('isIntableNumRange', isIntableNumRange)
    //     if (isIntableNumRange) {
    //         imgctx.strokeRect(
    //             originX, 
    //             originY, 
    //             tableNumRangeWidth, 
    //             tableNumRangeHeight
    //         )
    //         setElementSelected('tableNum')
    //     }
        
    //     // if (designPropertiesCurrent.textPosition)
    // }
    function canvasMouseDown(event) {
        // setIsMouseDown(true)
        mouseDown = true
        startX = parseInt(event.clientX)
        startY = parseInt(event.clientY)
    }
    function canvasMouseUp(event) {
        // setIsMouseDown(false)
        mouseDown = false
    }

    function canvasMouseMove(event) {
        if (mouseDown) {
            let deltaMouseX = parseInt(event.clientX) - startX
            let deltaMouseY = parseInt(event.clientY) - startY
            setDesignPropertiesCurrent(prevState => {
                if (elementSelected === 'all') {
                    return {
                        ...prevState,
                        textPosition: {
                            x1: prevState.textPosition.x1 + deltaMouseX / scale,
                            y1: prevState.textPosition.y1 + deltaMouseY / scale,
                            x2: prevState.textPosition.x2 + deltaMouseX / scale,
                            y2: prevState.textPosition.y2 + deltaMouseY / scale,
                            x3: prevState.textPosition.x3 + deltaMouseX / scale,
                            y3: prevState.textPosition.y3 + deltaMouseY / scale
                        }
                    }
                }
                else if (elementSelected === 'TableNum') {
                    return {
                        ...prevState,
                        textPosition: {
                            ...prevState.textPosition,
                            x1: prevState.textPosition.x1 + deltaMouseX / scale,
                            y1: prevState.textPosition.y1 + deltaMouseY / scale,
                        }
                    }
                }
                else if (elementSelected === 'TableName') {
                    return {
                        ...prevState,
                        textPosition: {
                            ...prevState.textPosition,
                            x2: prevState.textPosition.x2 + deltaMouseX / scale,
                            y2: prevState.textPosition.y2 + deltaMouseY / scale,
                        }
                    }
                }
                else if (elementSelected === 'MarryName') {
                    return {
                        ...prevState,
                        textPosition: {
                            ...prevState.textPosition,
                            x3: prevState.textPosition.x3 + deltaMouseX / scale,
                            y3: prevState.textPosition.y3 + deltaMouseY / scale,
                        }
                    }
                }
            })

            startX = parseInt(event.clientX)
            startY = parseInt(event.clientY)
        }
    }

    const fontSizeSlider = (elementSelected) => {
        if (elementSelected !== 'all') {
           return (<div className="option">
                <label for="fontSize">選擇字體大小：</label>
                <input 
                    type="range" 
                    className="fontSizeOption" 
                    id="fontSize" 
                    name="fontSize"
                    min="50"
                    max="350"
                    value={designPropertiesCurrent[`fontSize${elementSelected}`]}
                    onChange={handleFontSizeChange}
                    />
                </div>) 
        }
        else {
            return (<div className="option">
                <label for="fontSize">選擇字體大小：</label>
                <input 
                    type="range" 
                    className="fontSizeOption" 
                    id="fontSize" 
                    name="fontSize"
                    min="50"
                    max="350"
                    value={designPropertiesCurrent.fontColorTableNum}
                    onChange={handleFontSizeChange}
                    />
                </div>) 
        }
    }

    const colorPicker = (elementSelected) => {
        if (elementSelected !== 'all') {
           return (<div className="option">
           <label for="colorPicker">選擇字體顏色：</label>
           <SketchPicker 
               id="colorPicker" 
               name="colorPicker"
               color={designPropertiesCurrent[`fontColor${elementSelected}`]}
               onChange={handleColorChange}
               onChangeComplete={handleColorChange}
           />
            </div>) 
        }
        else {
            return (<div className="option">
            <label for="colorPicker">選擇字體顏色：</label>
            <SketchPicker 
                id="colorPicker" 
                name="colorPicker"
                color={designPropertiesCurrent.fontColorTableNum}
                onChange={handleColorChange}
                onChangeComplete={handleColorChange}
            />
            </div>) 
        }
    }

    return (
        <div className="previewContainer" ref={scrollRefPreview}>
            <div className="titleContainer">
                <h2 className="fileInputTitle">編輯和下載</h2>
                <div className="bottomLine black"></div>
            </div>
            <div className="previewDescriptionContainer">
                
                <h3>步驟一</h3><p>輸入結婚雙方英文名字以完整呈現桌卡，若桌卡上有字體跑掉的問題，點擊重新整理桌卡即可載入正確的字體</p>
                
                <h3>步驟二</h3><p>卡片尺寸有分為大尺寸及小尺寸，一張A4可以容納兩張大尺寸桌卡，或四張小尺寸桌卡。選擇要調整的文字(桌號、桌名、結婚雙方姓名)，並調整大小、顏色，滑鼠拖曳可以改變文字位置</p>
                
                <h3>步驟三</h3><p>點擊下載預覽檔案可以取得pdf檔檢視所有桌卡，灰色虛線處為剪裁線，影印並剪裁完成後將桌卡沿對摺線對摺即可完成桌卡</p>
            </div>
            <div className="editorContainer">
                <div className="editorOption">
                    <div className="option">
                        <label for="marryName">輸入結婚雙方的英文姓名：</label>
                        <input 
                            className="marryNameOption" 
                            id="marryName" 
                            name="marryName"
                            value={designPropertiesCurrent.marryName}
                            onChange={handleMarryNameChange}
                            placeholder="Mary & John"
                        />
                    </div>
                    <div className="option">
                        <label for="pageNum">選擇單面或雙面：</label>
                        <select className="layoutOption" id="pageNum" name="pageNum" onChange={handlePageNumChange}>
                            <option value="1">單面</option>
                            <option value="2">雙面</option>
                        </select>
                    </div>
                    <div className="option">
                        <label for="layout">選擇卡片大小：</label>
                        <select className="layoutOption" id="layout" name="layout" onChange={handleLayoutChange}>
                            <option value="large">大 ( 10.5公分 * 14.9公分 )</option>
                            <option value="small">小 ( 7.4公分 * 10.5公分 )</option>
                        </select>
                    </div>
                    <div className="option">
                        <label for="pageNum">選擇要調整的文字：</label>
                        <select className="layoutOption" id="elementSelect" name="elementSelect" onChange={handleElementSelect}>
                            <option value="all">全部</option>
                            <option value="TableNum">桌號</option>
                            <option value="TableName">桌名</option>
                            <option value="MarryName">結婚雙方姓名</option>
                        </select>
                    </div>
                    {fontSizeSlider(elementSelected)}
                    {colorPicker(elementSelected)}
                </div>
                <canvas 
                    ref={layer1} 
                    width={707 * scale} 
                    height={1000 * scale} 
                    className="preview"
                    style={{position: "relative" ,zIndex: 0}}
                />
            </div>
            
            <div className="buttonContainer">
                <button className="previewDownloadButton" onClick={handleCanvasRefresh}>重新整理桌卡</button>
                <button className="previewDownloadButton" onClick={handleReset}>回復預設樣式</button>
                <button className="previewDownloadButton" onClick={handlePreviewDownload}>下載檔案</button>
                {/* <button className="downloadButton" onClick={paymentSubmit}>下載正式檔案</button> */}
                {/* <form 
                    name='ECPay' 
                    method='post' 
                    action='https://hidden-shelf-72890.herokuapp.com/pay' 
                    onSubmit={handleDownloadSubmit} 
                    enctype='multipart/form-data'>
                    <input type='submit' value='下載正式檔案' className="downloadButton"/>
                    <input type='hidden' name='email' value={'seedmachinegun@gmail.com'}/>
                </form> */}
                {/* <form name='Newebpay' method='post' action='https://core.newebpay.com/MPG/mpg_gateway'>
                    <input type='hidden' name='MerchantID' value={trade_info_arr.MerchantID}/>
                    <input type='hidden' name='TradeInfo' value={TradeInfo}/>
                    <input type='hidden' name='TradeSha' value={TradeSha}/>
                    <input type='hidden' name='Version' value='1.5'/>
                    <input type='hidden' name='RespondType' value='JSON'/>
                    <input type='hidden' name='TimeStamp' value={trade_info_arr.TimeStamp}/>
                    <input type='hidden' name='MerchantOrderNo' value={trade_info_arr.MerchantOrderNo}/>
                    <input type='hidden' name='Amt' value={trade_info_arr.Amt}/>
                    <input type='hidden' name='ItemDesc' value={trade_info_arr.ItemDesc}/>
                    <input type='hidden' name='Email' value='seedmachinegun@gmail.com'/>
                    <input type='hidden' name='LoginType' value='0'/>
                    <input type='hidden' name='NotifyURL' value='https://hidden-shelf-72890.herokuapp.com/send'/>
                    <input type='hidden' name='ClientBackURL' value='https://www.simpleplacecard.com/'/>
                    <input type='hidden' name='ReturnURL' value='https://www.simpleplacecard.com/'/>
                    <input type='submit' value='下載正式檔案' className="downloadButton"/>
                </form> */}
            </div>
        </div>
    );
}
export default Preview;