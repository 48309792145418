import React from 'react';
import './Header.css';
import titleImage from './image/image1.jpg';

function Header({scrollToDesignOption}){
    return (
        <div className="outerContainer">
            <div className="innerContainer">
                <div className="titleContainer">
                    <h1 className="title">簡約婚禮桌卡 DIY</h1>
                    <div className="pinkBlock small"></div>
                </div>
                
                <div className="description">
                    <p className="description__text">快速製作典雅的桌牌，適用於婚禮、活動</p>
                    <p className="description__text">匯入桌號、桌子清單，匯出PDF檔即可開始印製</p>
                </div>
                <button 
                    className="startMakingButton" 
                    onClick={scrollToDesignOption}
                >開始製作</button>
            </div>
            <div className="imageContainer">
                <img className="headerImage" src={titleImage} alt=""/>
                <div className="pinkBlock big"></div>
            </div>
            <div className="pinkBackgroundContainer">
                <img alt=""/>
            </div>
        </div>
        
    );
}

export default Header;