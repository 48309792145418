
import React, { useEffect, createRef } from "react";
import './Preview.css';


function ExportPdf({
    excelData, 
    canvasRefs, 
    setCanvasRefs, 
    optionSelected, 
    canvasRefreshCount,
    pageNum,
    designPropertiesCurrent,
    designPropertiesDefault
  }){

    useEffect(() => {
        if (optionSelected === 'copyPaste'){
          setCanvasRefs(prevCanvasRefs => {
            return designPropertiesCurrent.text.split('\n').map((canvas, index) => prevCanvasRefs[index] || createRef() );
            }
          );

        }
        else {
          setCanvasRefs(prevCanvasRefs => {
            return excelData.map((canvas, index) => prevCanvasRefs[index] || createRef() );
            }
          );
        }
        
        
    
      }, [designPropertiesCurrent.text, excelData, optionSelected, setCanvasRefs]);
    
      useEffect(() => {
        let img = new Image();
        img.src = require(`${designPropertiesCurrent.imagePath}`);

        img.onload = function(){
          if (optionSelected === 'copyPaste'){

            textDrawOnCanvas(canvasRefs, designPropertiesCurrent.text, img, pageNum);
          }
          else {
            // console.log(canvasRefs);
  
            excelDrawOnCanvas(canvasRefs, excelData, img, pageNum);
          }
        };
    
      }, [designPropertiesCurrent.text, 
        canvasRefs, 
        excelData, 
        designPropertiesCurrent.imagePath, 
        optionSelected, 
        designPropertiesCurrent.marryName, 
        designPropertiesCurrent.textPosition, 
        designPropertiesCurrent.fontColorTableNum,
        designPropertiesCurrent.fontColorTableName,
        designPropertiesCurrent.fontColorMarryName,
        designPropertiesCurrent.fontSizeTableNum,
        designPropertiesCurrent.fontSizeTableName,
        designPropertiesCurrent.fontSizeMarryName,
        canvasRefreshCount, 
        pageNum]);
          
    
      function textDrawOnCanvas(canvasRefsArr, text, img, pageNum){
        let nameTableList = text.split('\n');
            
        for (let [id, canvasRef] of canvasRefsArr.entries()){
            if (id + 1 > nameTableList.length) break; // check if the canvasRef id is bigger than the nameTableList, 
            let ctx = canvasRef.current.getContext('2d'); //preventing the program to crash when deleting text from the textarea
            let [name, table] = ['', ''];
            if (nameTableList[id].length > 1){
                name = nameTableList[id].split(',')[0];
                table = nameTableList[id].split(',')[1] === undefined ? '' : nameTableList[id].split(',')[1];
            }
            drawOnCanvas(ctx, img, name, table, pageNum);
            
            
        }
      }

      function excelDrawOnCanvas(canvasRefsArr, excelData, img, pageNum){
            
        for (let [id, canvasRef] of canvasRefsArr.entries()){
            if (id + 1 > excelData.length) break; // check if the canvasRef id is bigger than the nameTableList, 
            let ctx = canvasRef.current.getContext('2d'); //preventing the program to crash when deleting text from the textarea
            let [name, table] = ['', ''];
            if (excelData[id].length > 1){
                [name, table] = excelData[id];
            }
            drawOnCanvas(ctx, img, name, table, pageNum);
        }
      }

      function drawOnCanvas(ctx, img, name, table, pageNum){
          // ctx.drawImage(img, 0, 0, 1200, 800);
          // ctx.textAlign = "center";
          // ctx.textBaseLine = "middle";
          // ctx.font = "150px San Serif";
          // ctx.fillStyle = "#D7707F";
          // ctx.fillText(name, 600, 450);

          // ctx.font = "80px San Serif";
          // ctx.fillStyle = "#E8AEB7";
          // ctx.fillText(table, 600, 600);


          // ctx.clearRect(0, 0, 1200, 800);
          // ctx.drawImage(img, (1200 - (img.width * 800 / img.height)) / 2, 0, img.width * ( 800 / img.height ) , 800);
          // ctx.textAlign = "center";
          // ctx.textBaseLine = "middle";
          // ctx.font = "150px SourceHanSansTC";
          // ctx.fillStyle = "#D7707F";
          // ctx.fillText(name, textPosition.x1, textPosition.y1);

          // ctx.font = "80px SourceHanSansTC";
          // ctx.fillStyle = "#E8AEB7";
          // ctx.fillText(table, textPosition.x2, textPosition.y2);

          
          ctx.clearRect(0, 0, 707, 2000);
          ctx.drawImage(img, (707 - (img.width * 1000 / img.height)) / 2, 1000, img.width * ( 1000 / img.height ) , 1000);
          ctx.textAlign = "center";
          ctx.textBaseLine = "middle";
          ctx.font = `${designPropertiesCurrent.fontSizeTableNum}px Noto Serif TC`;
          ctx.fillStyle = `${designPropertiesCurrent.fontColorTableNum}`;
          ctx.fillText(name, designPropertiesCurrent.textPosition.x1, designPropertiesCurrent.textPosition.y1 + 1000);  // 加1000是因為要留空間作折疊

          ctx.font = `${designPropertiesCurrent.fontSizeTableName}px Noto Serif TC`;
          ctx.fillStyle = `${designPropertiesCurrent.fontColorTableName}`;
          ctx.fillText(table, designPropertiesCurrent.textPosition.x2, designPropertiesCurrent.textPosition.y2 + 1000);

          ctx.font = `${designPropertiesCurrent.fontSizeMarryName}px Scaramella-Regular`;
          ctx.fillStyle = `${designPropertiesCurrent.fontColorMarryName}`;
          ctx.fillText(designPropertiesCurrent.marryName, designPropertiesCurrent.textPosition.x3, designPropertiesCurrent.textPosition.y3 + 1000);
          
          // 虛線用於剪裁
          ctx.strokeStyle = "#333";
          ctx.setLineDash([12, 24]);
          ctx.strokeRect(0,0,707, 2000);

          // ctx.font = '350px 微軟正黑體';
          // ctx.fillStyle = "rgba(138, 138, 138, 0.55)";;
          // ctx.fillText('預覽', 353, 1600);

          // 如果使用者設定是雙面，ctx畫布要轉一圈再畫上一樣的東西
          // console.log('export pdf Pagenum,', pageNum);

          if (pageNum === "2") {
            ctx.save(); // save canvas state
            ctx.translate(707, 2000);
            ctx.rotate(Math.PI);

            ctx.drawImage(img, (707 - (img.width * 1000 / img.height)) / 2, 1000, img.width * ( 1000 / img.height ) , 1000);
            ctx.textAlign = "center";
            ctx.textBaseLine = "middle";
            ctx.font = `${designPropertiesCurrent.fontSizeTableNum}px Noto Serif TC`;
            ctx.fillStyle = `${designPropertiesCurrent.fontColorTableNum}`;
            ctx.fillText(name, designPropertiesCurrent.textPosition.x1, designPropertiesCurrent.textPosition.y1 + 1000);  // 加1000是因為要留空間作折疊

            ctx.font = `${designPropertiesCurrent.fontSizeTableName}px Noto Serif TC`;
            ctx.fillStyle = `${designPropertiesCurrent.fontColorTableName}`;
            ctx.fillText(table, designPropertiesCurrent.textPosition.x2, designPropertiesCurrent.textPosition.y2 + 1000);

            ctx.font = `${designPropertiesCurrent.fontSizeMarryName}px Scaramella-Regular`;
            ctx.fillStyle = `${designPropertiesCurrent.fontColorMarryName}`;
            ctx.fillText(designPropertiesCurrent.marryName, designPropertiesCurrent.textPosition.x3, designPropertiesCurrent.textPosition.y3 + 1000);

            ctx.strokeStyle = "#333";
            ctx.setLineDash([12, 24]);
            ctx.strokeRect(0,0,707, 2000);
            
            ctx.restore(); //restore canvas state
          }

          
      }


    
    return (
        <div>
            { optionSelected === 'copyPaste' ?
              (
              designPropertiesCurrent.text.split('\n').map((canvas, index) => (
              <canvas
              ref={canvasRefs[index]}
              key={index}
              style={{border: "1px solid black", display: 'none'}}
              width={707} 
              height={2000} 
              />
              ))
              )
              :
              (
                excelData.map((canvas, index) => (
                <canvas
                ref={canvasRefs[index]}
                key={index}
                style={{border: "1px solid black", display: 'none'}}
                width={707} 
                height={2000} 
                />
                ))
              )
            }
        </div>
    );
}

export default ExportPdf;