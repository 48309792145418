import React from 'react';
import './Footer.css';
import grayBackground from './image/grayBackground.png';

function Footer(){
    return (
        <div className="footerContainer">
            <p className="contact">若有疑難或建議，歡迎來信 simpleplacecard@gmail.com</p>
            <p className="contact"> Copyright © 2020 P.Lu All rights reserved</p>
            <img className="grayBackground" src={grayBackground} alt=""/>
        </div>
    );
}

export default Footer;