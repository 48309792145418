export const designPropertiesDefault = {
    blank: {
      id: 0,
      name: 'blank',
      displayName: '空白',
      textPosition: {x1: 353, y1: 470, x2: 353, y2: 720, x3: 353, y3: 830},
      fontColorTableNum: 'black',
      fontColorTableName: 'black',
      fontColorMarryName: 'black',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image0.jpg'
    },
    flower1: {
      id: 1,
      name: 'flower1',
      displayName: '花朵一',
      textPosition: {x1: 353, y1: 470, x2: 353, y2: 720, x3: 353, y3: 830},
      fontColorTableNum: '#743030',
      fontColorTableName: '#743030',
      fontColorMarryName: '#743030',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image5.jpg'
    },
    flower2: {
      id: 2,
      name: 'flower2',
      displayName: '花朵二',
      textPosition: {x1: 353, y1: 450, x2: 353, y2: 720, x3: 353, y3: 830},
      fontColorTableNum: '#ebb534',
      fontColorTableName: '#ebb534',
      fontColorMarryName: '#ebb534',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image6.jpg'
    },
    flower3: {
      id: 3,
      name: 'flower3',
      displayName: '花朵三',
      textPosition: {x1: 353, y1: 450, x2: 353, y2: 720, x3: 353, y3: 830},
      fontColorTableNum: '#262525',
      fontColorTableName: '#262525',
      fontColorMarryName: '#262525',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image7.jpg'
    },
    flower4: {
      id: 4,
      name: 'flower4',
      displayName: '花朵四',
      textPosition: {x1: 353, y1: 470, x2: 353, y2: 720, x3: 353, y3: 830},
      fontColorTableNum: 'black',
      fontColorTableName: 'black',
      fontColorMarryName: 'black',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image8.jpg'
    },
    flower5: {
      id: 5,
      name: 'flower5',
      displayName: '花朵五',
      textPosition: {x1: 353, y1: 470, x2: 353, y2: 685, x3: 353, y3: 830},
      fontColorTableNum: '#ebb534',
      fontColorTableName: '#ebb534',
      fontColorMarryName: '#ebb534',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image9.jpg'
    },
    flower6: {
      id: 6,
      name: 'flower6',
      displayName: '花朵六',
      textPosition: {x1: 353, y1: 470, x2: 353, y2: 720, x3: 353, y3: 830},
      fontColorTableNum: '#a21c15',
      fontColorTableName: '#a21c15',
      fontColorMarryName: '#a21c15',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image10.jpg'
    },
    arora: {
      id: 7,
      name: 'arora',
      displayName: '極光',
      textPosition: {x1: 148, y1: 224, x2: 200, y2: 968, x3: 570, y3: 398},
      fontColorTableNum: '#030a12',
      fontColorTableName: '#FFFFFF',
      fontColorMarryName: '#030a12',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image11.jpg'
    },
    ocean: {
      id: 8,
      name: 'ocean',
      displayName: '海洋',
      textPosition: {x1: 200, y1: 300, x2: 500, y2: 830, x3: 200, y3: 920},
      fontColorTableNum: '#1e2430',
      fontColorTableName: '#1e2430',
      fontColorMarryName: '#1e2430',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image12.jpg'
    },
    classicBlue: {
      id: 9,
      name: 'classicBlue',
      displayName: '典雅藍',
      textPosition: {x1: 353, y1: 420, x2: 353, y2: 680, x3: 353, y3: 850},
      fontColorTableNum: '#f2af29',
      fontColorTableName: '#f2af29',
      fontColorMarryName: '#f2af29',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image13.jpg'
    },
    classicRed: {
      id: 10,
      name: 'classicRed',
      displayName: '典雅紅',
      textPosition: {x1: 353, y1: 420, x2: 353, y2: 680, x3: 353, y3: 850},
      fontColorTableNum: '#f2af29',
      fontColorTableName: '#f2af29',
      fontColorMarryName: '#f2af29',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image14.jpg'
    },
    frameRed: {
      id: 11,
      name: 'frameRed',
      displayName: '典雅框紅',
      textPosition: {x1: 353, y1: 400, x2: 353, y2: 660, x3: 353, y3: 820},
      fontColorTableNum: '#f2af29',
      fontColorTableName: '#f2af29',
      fontColorMarryName: '#f2af29',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image15.jpg'
    },
    redFlower: {
      id: 12,
      name: 'redFlower',
      displayName: '紅花',
      textPosition: {x1: 520, y1: 680, x2: 480, y2: 800, x3: 353, y3: 920},
      fontColorTableNum: '#ae2626',
      fontColorTableName: '#ae2626',
      fontColorMarryName: '#ae2626',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image16.jpg'
    },
    redFlower2: {
      id: 13,
      name: 'redFlower2',
      displayName: '花紅',
      textPosition: {x1: 353, y1: 450, x2: 353, y2: 720, x3: 353, y3: 830},
      fontColorTableNum: '#ebb534',
      fontColorTableName: '#ebb534',
      fontColorMarryName: '#ebb534',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image17.jpg'
    },
    decoRed: {
      id: 14,
      name: 'decoRed',
      displayName: 'Deco紅',
      textPosition: {x1: 353, y1: 420, x2: 353, y2: 650, x3: 353, y3: 820},
      fontColorTableNum: '#f2af29',
      fontColorTableName: '#f2af29',
      fontColorMarryName: '#f2af29',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image18.jpg'
    },
    designPink: {
      id: 15,
      name: 'designPink',
      displayName: '花粉紅',
      textPosition: {x1: 260, y1: 260, x2: 353, y2: 850, x3: 353, y3: 930},
      fontColorTableNum: '#e28888',
      fontColorTableName: '#e28888',
      fontColorMarryName: '#e28888',
      fontSizeTableNum: 250,
      fontSizeTableName: 90,
      fontSizeMarryName: 50,
      text: '',
      marryName: '',
      imagePath: './image/image19.jpg'
    }
  }