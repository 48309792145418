import React, {useState, createRef, useEffect} from 'react';
import './App.css';
import spinner from './components/image/Spinner-1s-200px.gif';
import FileInput from './components/FileInput';
import Preview from './components/Preview';
import ExportPdf from './components/ExportPdf';
import DesignOptions from './components/DesignOptions';
import Header from './components/Header';
import Footer from './components/Footer';
import jsPDF from 'jspdf';
import XLSX from 'xlsx';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';

import { designPropertiesDefault } from './designPropertiesDefault';


function App() {
  /*
    TODOS:
    - find a way to allow use to pay
    - add price desciprtion
    - pdf download in mobile not working
    - 把編輯部驟變好看
    - \n 有兩個以上會看步道Preview
    - add loading logo in index.html
    - IE compatibility
    - cross browser issue
    - Google analytics button tracking
  */

  useEffect(() => {
    ReactGA.initialize('UA-175215758-1');
    //to report page view

    ReactGA.pageview('/');
  }, []);

  const [excelData, setExcelData] = useState([]);
  const [text, setText] = useState('');
  const [canvasRefs, setCanvasRefs] = useState([]);
  const [layoutSelected, setLayoutSelected] = useState('large');
  // could be table number, table name, marry name or all
  const [elementSelected, setElementSelected] = useState('all')
  const [fontSize, setFontSize] = useState(250);
  const [fontColor, setFontColor] = useState('#D7707F');
  const [textPosition, setTextPosition] = useState({});
  const [marryName, setMarryName] = useState('');
  const [canvasRefreshCount, setCanvasRefreshCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNum, setPageNum] = useState('1');

  // refactoring
  const [designPropertiesCurrent, setDesignPropertiesCurrent] = useState(designPropertiesDefault.blank)

  const scrollRefDesignOption = createRef();
  const scrollRefFileInput = createRef();
  const scrollRefPreview = createRef();

  const [optionSelected, setOptionSelected] = useState('copyPaste');
  
  const designProperties = {
    './image/image0.jpg': { 
                            textPosition: {x1: 353, y1: 470, x2: 353, y2: 720, x3: 353, y3: 830},
                            fontColor: 'black',
                          },
    './image/image5.jpg': { 
                            textPosition: {x1: 353, y1: 470, x2: 353, y2: 720, x3: 353, y3: 830},
                            fontColor: '#743030',
                          },    
    './image/image6.jpg': { 
                            textPosition: {x1: 353, y1: 450, x2: 353, y2: 720, x3: 353, y3: 830},
                            fontColor: '#ebb534',
                          },
    './image/image7.jpg': { 
                            textPosition: {x1: 353, y1: 450, x2: 353, y2: 720, x3: 353, y3: 830},
                            fontColor: '#262525',
                          },
    './image/image8.jpg': { 
                            textPosition: {x1: 353, y1: 470, x2: 353, y2: 720, x3: 353, y3: 830},
                            fontColor: 'black',
                          },
    './image/image9.jpg': { 
                            textPosition: {x1: 353, y1: 470, x2: 353, y2: 685, x3: 353, y3: 830},
                            fontColor: '#ebb534',
                          },
    './image/image10.jpg': { 
                            textPosition: {x1: 353, y1: 470, x2: 353, y2: 720, x3: 353, y3: 830},
                            fontColor: '#a21c15',
                          },
  }

  function activateInputMethod(event){
      setOptionSelected(event.target.getAttribute("name"));
  }

  const handleChange = (event) => {
      let data = event.target.value;
      setDesignPropertiesCurrent(prevState => {
        return {
          ...prevState,
          text: data
        }
      });
  };

  const handleFileChange = (event) => {
      toast("成功上傳 Excel 檔！");
      ReactGA.event({
        category: 'Button',
        action: '按下上傳Excel檔按鈕'
      });

      let files = event.target.files;
      let f = files[0];
      let reader = new FileReader();
      reader.onload = function(event) {
        let data = new Uint8Array(event.target.result);
        let workbook = XLSX.read(data, {type: 'array'});
        let firstSheet = workbook.Sheets[workbook.SheetNames[0]];

        let result = XLSX.utils.sheet_to_json(firstSheet, {header: 1});

        setExcelData(result);
      }
      reader.readAsArrayBuffer(f);
  };

  useEffect(() => {     // initialize the preview
    setFontColor(designProperties['./image/image0.jpg'].fontColor);
    setTextPosition(designProperties['./image/image0.jpg'].textPosition);
  },[]);


  const handleImageClick = (event) => {
    const designName = event.target.getAttribute('name');
    ReactGA.event({
      category: 'Design Template',
      action: `使用${designName}範本`
    });
    setDesignPropertiesCurrent(prevState => {
      return {
        ...designPropertiesDefault[designName],
        text: prevState.text,
        marryName: prevState.marryName
      }
    });
    scrollToFileInput();
  }

  const handleMarryNameChange = (event) => {
    const marryName = event.target.value
    setDesignPropertiesCurrent(prevState => {
      return {
        ...prevState,
        marryName: marryName
      }
    });
  }

  const handleFontSizeChange = (event) => {
    const fontSize = event.target.value
    if (elementSelected !== 'all') {
      setDesignPropertiesCurrent(prevState => {
        return {
          ...prevState,
          [`fontSize${elementSelected}`]: fontSize
        }
      })
    }
    else {
      setDesignPropertiesCurrent(prevState => {
        return {
          ...prevState,
          fontSizeTableNum: fontSize,
          fontSizeTableName: fontSize * 90 / 250,
          fontSizeMarryName: fontSize * 50 / 250
        }
      })
    }
  };
  
  const handleColorChange = (color) => {
    if (elementSelected !== 'all') {
      setDesignPropertiesCurrent(prevState => {
        return {
          ...prevState,
          [`fontColor${elementSelected}`]: color.hex
        }
      })
    }
    else {
      setDesignPropertiesCurrent(prevState => {
        return {
          ...prevState,
          fontColorTableNum: color.hex,
          fontColorTableName: color.hex,
          fontColorMarryName: color.hex
        }
      })
    }
  }

  const handleLayoutChange = (event) => {
    setLayoutSelected(event.target.value);
  }

  function handleElementSelect(event) {
    setElementSelected(event.target.value)
  }

  const handleCanvasRefresh = () => {
    setCanvasRefreshCount(preState => preState + 1);
  };

  const handlePageNumChange = (event) => {
    setPageNum(event.target.value);
  }

  const handleReset = () => {
    const designName = designPropertiesCurrent.name;
    setDesignPropertiesCurrent(prevState => {
      return {
        ...designPropertiesDefault[designName],
        text: prevState.text,
        marryName: prevState.marryName
      }
    });
  }

  const handleSubmit = (event) => {
    scrollToPreview();
  };

  const handlePreviewDownload = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'Button',
      action: '按下下載預覽檔案按鈕'
    });
    setIsLoading(true);
    canvasToPdf(true);
  };

  const handleDownloadSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'Button',
      action: '按下下載正式檔案按鈕'
    });
    setIsLoading(true);
    canvasToPdf(false, event);
  }

  const canvasToPdf = (isPreview, event) => {
    if (layoutSelected === 'large'){
      let pdf = new jsPDF();
      let pdfWidth = pdf.internal.pageSize.getWidth();
      let pdfHeight = pdf.internal.pageSize.getHeight();

      let canvasLayerNumber = 1;

      for (let [index, canvasRef] of canvasRefs.entries()){
        let imgData = canvasRef.current.toDataURL("image/png", 1.0);
        let x = (index % 2) * pdfWidth / 2;
        let y = 0;
        pdf.addImage(imgData, 'PNG', x, y, pdfWidth / 2, pdfHeight);
        
        if (y + (pdfHeight) >= pdfHeight && (index % 2 === 1) && canvasRefs.length > index + 1 ){
          pdf.addPage();
        }
      }
      setIsLoading(false);
      processPdf(pdf, isPreview, event);

    } else {
      let pdf = new jsPDF('l');
      let pdfWidth = pdf.internal.pageSize.getWidth();
      let pdfHeight = pdf.internal.pageSize.getHeight();

      for (let [index, canvasRef] of canvasRefs.entries()){


        let imgData = canvasRef.current.toDataURL("image/png", 1.0);
        let x = (index % 4) * (10.5 * pdfHeight / 29.8);
        let y = 0;
        pdf.addImage(imgData, 'PNG', x, y, 10.5 * pdfHeight / 29.8, pdfHeight);
        
        
        if ((index % 4 === 3) && canvasRefs.length > index + 1 ){
          pdf.addPage('a4', 'landscape');
        }
      }
      setIsLoading(false);
      processPdf(pdf, isPreview, event);
    }
  }

  const processPdf = (pdf, isPreview, event) => {
    if (isPreview) {
      pdf.save("card.pdf");
    }
    // Download 
    else {
      // debugger
      const blobPdf =  pdf.output('blob');
      const form = event.currentTarget;
      const url = form.action;

      const formData = new FormData(form);
      formData.append('pdf', blobPdf, 'Place_Card.pdf')

      fetch(url, {
        method: 'post',
        body: formData,
        redirect: 'follow'
      })
      .then(res => {
        console.log('res', res)
        console.log('res.status', res.status)
        if (res.redirected) {
          window.location = res.url
        }
      })
      .catch(err => {
        console.error(err)
      })
    }
  }
  // TODO: Clear warnings
  // TODO: Delete multer upload file?
  // TODO: perforamce improvement
  // useEffect(() => { // 把canvas 畫到pdf上z

  //   if (isLoading){
  //     if (layoutSelected === 'large'){
  //       let pdf = new jsPDF();
  //       let pdfWidth = pdf.internal.pageSize.getWidth();
  //       let pdfHeight = pdf.internal.pageSize.getHeight();
  
  //       let canvasLayerNumber = 1;
  
  //       for (let [index, canvasRef] of canvasRefs.entries()){
  //         let imgData = canvasRef.current.toDataURL("image/png", 1.0);
  //         let x = (index % 2) * pdfWidth / 2;
  //         let y = 0;
  //         pdf.addImage(imgData, 'PNG', x, y, pdfWidth / 2, pdfHeight);
          
  //         if (y + (pdfHeight) >= pdfHeight && (index % 2 === 1) && canvasRefs.length > index + 1 ){
  //           pdf.addPage();
  //         }
  //       }
  //       setIsLoading(false);
  //       pdf.save("card.pdf");
  
  //     } else {
  //       let pdf = new jsPDF('l');
  //       let pdfWidth = pdf.internal.pageSize.getWidth();
  //       let pdfHeight = pdf.internal.pageSize.getHeight();
  
  //       for (let [index, canvasRef] of canvasRefs.entries()){
  
  
  //         let imgData = canvasRef.current.toDataURL("image/png", 1.0);
  //         let x = (index % 4) * (10.5 * pdfHeight / 29.8);
  //         let y = 0;
  //         pdf.addImage(imgData, 'PNG', x, y, 10.5 * pdfHeight / 29.8, pdfHeight);
          
          
  //         if ((index % 4 === 3) && canvasRefs.length > index + 1 ){
  //           pdf.addPage('a4', 'landscape');
  //         }
  //       }
  //       setIsLoading(false);
  //       pdf.save("card.pdf");
  //     }
  //   }
  // }, [isLoading]);

  const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'});
  const scrollToDesignOption = () => {
    scrollToRef(scrollRefDesignOption);  
    ReactGA.event({
      category: 'Button',
      action: '按下開始製作按鈕'
    });
  };
  const scrollToFileInput = () => {
    scrollToRef(scrollRefFileInput);
    ReactGA.event({
      category: 'Button',
      action: '按下選擇範本'
    });
  };
  const scrollToPreview = () => {
    scrollToRef(scrollRefPreview)
    ReactGA.event({
      category: 'Button',
      action: '按下確認上傳檔案'
    });
  };

  const testfetch = () => {
    let url = 'https://hidden-shelf-72890.herokuapp.com/';
    let data = {
      hello: 'hi'
    }
    fetch(url).then(res => res.json()).then(response => console.log(response));
    fetch(url + 'send', {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => console.log('Success:', response));
    alert('hi');
  };



  return (
    <div className="App">
      <Header scrollToDesignOption={scrollToDesignOption}/>
      <DesignOptions 
        handleImageClick={handleImageClick} 
        id="DesignOptions" 
        scrollRefDesignOption={scrollRefDesignOption}
        image={designPropertiesCurrent.imagePath}
      />
      <FileInput 
        designPropertiesCurrent={designPropertiesCurrent} 
        handleChange={handleChange} 
        handleSubmit={handleSubmit} 
        handleFileChange={handleFileChange}
        optionSelected={optionSelected}
        activateInputMethod={activateInputMethod}
        scrollRefFileInput={scrollRefFileInput}
      />
      {/* <button onClick={testfetch}>Test</button> */}
      <Preview 
        excelData={excelData}
        optionSelected={optionSelected}
        handleFontSizeChange={handleFontSizeChange}
        handleColorChange={handleColorChange}
        handleMarryNameChange={handleMarryNameChange}
        handleElementSelect={handleElementSelect}
        handleLayoutChange={handleLayoutChange}
        handlePreviewDownload={handlePreviewDownload}
        handleDownloadSubmit={handleDownloadSubmit}
        scrollRefPreview={scrollRefPreview}
        canvasRefreshCount={canvasRefreshCount}
        handleCanvasRefresh={handleCanvasRefresh}
        handlePageNumChange={handlePageNumChange}
        designPropertiesCurrent={designPropertiesCurrent}
        setDesignPropertiesCurrent={setDesignPropertiesCurrent}
        designPropertiesDefault={designPropertiesDefault}
        elementSelected={elementSelected}
        handleReset={handleReset}
      />
      {
        isLoading 
        ? 
        (<div className="loading">
          <img src={spinner} alt="" />
          <p>請稍後，pdf檔產生中...</p>
        </div>) 
        : 
        ''
      }
      <ExportPdf 
        excelData={excelData}
        canvasRefs={canvasRefs} 
        setCanvasRefs={setCanvasRefs}
        optionSelected={optionSelected}
        canvasRefreshCount={canvasRefreshCount}
        pageNum={pageNum}
        designPropertiesCurrent={designPropertiesCurrent}
        designPropertiesDefault={designPropertiesDefault}
      />

      <Footer/>
    </div>
  );
}

export default App;
