import React from 'react';
import image0 from './image/image0.jpg';
import image5_example from './image/image5-example.jpg';
import image6_example from './image/image6-example.jpg';
import image7_example from './image/image7-example.jpg';
import image9_example from './image/image9-example.jpg';
import image10_example from './image/image10-example.jpg';
import image11_example from './image/image11-example.jpg';
import image12_example from './image/image12-example.jpg';
import image13_example from './image/image13-example.jpg';
import image14_example from './image/image14-example.jpg';
import image15_example from './image/image15-example.jpg';
import image16_example from './image/image16-example.jpg';
import image17_example from './image/image17-example.jpg';
import image18_example from './image/image18-example.jpg';
import image19_example from './image/image19-example.jpg';

import pinkBackground from './image/pinkBackground.png';
import './DesignOptions.css';

function DesignOptions({handleImageClick, scrollRefDesignOption, image}){
    return (
        <div className="designOptionOuterContainer" ref={scrollRefDesignOption}>
            <div className="titleContainer">
                <h2 className="designOptionTitle">選擇設計</h2>
                <div className="bottomLine white"></div>
            </div>
            <p className="designOptionDescription">所有模板都限時免費，於3/1後將改為付費使用模板，使用模板將酌收 66 元以維持這個網站的營運，無背景的設計則仍為免費，非常感謝您的支持！</p>
            {/*First Slide*/}
            <div className="designOptionRow">
                <div  className={"designOptionContainer" + ((image === './image/image0.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image0} 
                        name="blank"
                        alt="Design0"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image5.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image5_example} 
                        name="flower1"
                        alt="Design0"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image6.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image6_example} 
                        name="flower2"
                        alt="Design0"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image7.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image7_example} 
                        name="flower3"
                        alt="Design0"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image9.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image9_example} 
                        name="flower5"
                        alt="Design0"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image10.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image10_example} 
                        name="flower6"
                        alt="Design0"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image19.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image19_example} 
                        name="designPink"
                        alt="designPink"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image11.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image11_example} 
                        name="arora"
                        alt="arora"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image12.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image12_example} 
                        name="ocean"
                        alt="ocean"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image13.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image13_example} 
                        name="classicBlue"
                        alt="classicBlue"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image14.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image14_example} 
                        name="classicRed"
                        alt="classicRed"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image15.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image15_example} 
                        name="frameRed"
                        alt="frameRed"></img>
                </div>
                <div  className={"designOptionContainer" + ((image === './image/image16.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image16_example} 
                        name="redFlower"
                        alt="redFlower"></img>
                </div>
                {/* <div  className={"designOptionContainer" + ((image === './image/image17.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image17_example} 
                        name="redFlower2"
                        alt="redFlower2"></img>
                </div> */}
                <div  className={"designOptionContainer" + ((image === './image/image18.jpg') ? " designActivated" : "")}  >
                    <img 
                        onClick={handleImageClick} 
                        className="designOption"
                        src={image18_example} 
                        name="decoRed"
                        alt="decoRed"></img>
                </div>
            </div>
            <img className="pinkBackground" src={pinkBackground} alt=""/>
        </div>
    );

}


export default DesignOptions;