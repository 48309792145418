import React from "react";
import './FileInput.css';
import copyPasteExample from './image/copyPaste_example.png';
import excelExample from './image/excel_example.png';
import fileUploadImage from './image/folder-upload.svg';

function FileInput({
    designPropertiesCurrent, 
    handleChange, 
    handleFileChange, 
    handleSubmit, 
    optionSelected, 
    activateInputMethod, 
    scrollRefFileInput
}) {
    return(
        <div className="fileInputContainer" ref={scrollRefFileInput}>
            <div className="titleContainer">
                <h2 className="fileInputTitle">匯入清單</h2>
                <div className="bottomLine gray"></div>
            </div>
            <div className="optionContainer">
                <button className={(optionSelected === 'copyPaste' ? "optionActivated" : "optionButton")} name="copyPaste" onClick={activateInputMethod}>使用複製貼上</button>
                <button className={(optionSelected === 'excelUpload' ? "optionActivated" : "optionButton")} name="excelUpload" onClick={activateInputMethod}>使用Excel上傳</button>
            </div>
            
            {optionSelected === 'copyPaste' ? 

            (<div className="fileInputMethod">
                <div className="descriptionContainer">
                    <h3 className="optionDescription">使用複製貼上</h3>
                    <p className="optionDescription">將您的名單複製貼上，用逗號 "," 將桌號和桌名分開，格式如下圖所示</p>
                    <img src={copyPasteExample} alt="copy paste data input example"/>
                </div>
                <label for="nameInput"></label>
                <textarea 
                    id="nameInput" 
                    name="nameInput"
                    rows="20" 
                    cols="40"
                    onChange={handleChange} 
                    value={designPropertiesCurrent.text}
                    className="textArea"
                    placeholder="桌號,桌名&#10;桌號,桌名&#10;桌號,桌名"
                >
                </textarea>
            </div>)

            :

            (<div className="fileInputMethod">
                <div className="descriptionContainer">
                    <h3 className="optionDescription">使用Excel上傳</h3>
                    <p className="optionDescription">將您的Excel檔上傳，格式如下圖所示</p>
                    <img src={excelExample} alt="excel data input example"/>
                </div>
                <div className="fileSelectContainer">
                    <label for="fileSelect">
                        <img src={fileUploadImage} className="fileSelectImage" alt=""/>
                    </label>
                    <input 
                        id="fileSelect" 
                        type="file" 
                        accept=".csv, 
                                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                                application/vnd.ms-excel" 
                        className="fileSubmit"
                        onChange={handleFileChange}
                    />

                </div>
            </div>)
            }
            
            
            <button className="submitButton" type="submit" id="submit" onClick={handleSubmit}>確認上傳名單</button>
        </div>
    );

}

export default FileInput;